// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Hi, I am Dani",
  middleName: "",
  lastName: "Murcia",
  message: " Software developer in continuous learning, self-taught and motivated ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/danijmurcia",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/dani-murcia-b137441a1/",
    },
  ],
};

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/dani.jpg"),
  imageSize: 375,
  message:
    "I'm a software developer, currently working in a Málaga company with experience in web apps. With great experience in building custom and scalable solutions.",
  resume: "../../resume.pdf",
};

const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "danijmurcia",
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/dani.jpg"),
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/dani.jpg"),
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Main Skills",
  hardSkills: [
    { name: "Laravel", value: 90 },
    { name: "Vue JS", value: 85 },
    { name: "GIT", value: 90 },
    { name: "PHP", value: 90 },
    { name: "Flutter", value: 50 },
    { name: "Docker", value: 70 },
    { name: "SQL", value: 80 },
    { name: "Wordpress", value: 90 },
    { name: "Agile methodologies", value: 90 },
    { name: "Design Patterns", value: 80 },
    { name: "Microservices", value: 70 },
    { name: "PHPUnit", value: 70 },
    { name: "RabbitMQ", value: 70 },
  ],
  softSkills: [
    { name: "Team Work", value: 95 },
    { name: "Positivity", value: 90 },
    { name: "Adaptability", value: 90 },
    { name: "Communication", value: 90 },
    { name: "Organization", value: 80 },
    { name: "Eager to learn", value: 100 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you know of any positions available, if you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "danijmurcia@gmail.com",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      role: 'Airzone Spain',
      companylogo: require('../assets/img/logo-airzone.png'),
      date: 'October 2021 – Present',
      description: 'Product based company, internal application for the administration of the company and a public "e-commerce" with a complex business model',
      stack: 'Laravel, Vue JS, Docker'
    },
    {
      role: 'SmartWay Studio',
      companylogo: require('../assets/img/logo-smartway.png'),
      date: 'June 2020 – October 2021',
      description: 'Technological Consulting',
      stack: 'Native PHP, Vue JS, .NET, EntityFramework, Laravel, Docker, ElasticSearch, Kibana, Wordpress'
    },
    {
      role: 'Scorpora Tecno App',
      companylogo: require('../assets/img/logo-scorpora.png'),
      date: 'March 2019 – June 2020',
      description: 'Scorpora Tecno App is a company for IT solutions development on the ISP sector with presence in Spain and LATAM.',
      stack: 'Native PHP, Javascript, Laravel, Angular'
    },
  ]
}

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
